export const URLS = {
  /*USER URL */
  GET_USER_DETAILS: "/users",
  ADD_USER_DETAILS: "/users",
  UPDATE_USER_DETAILS: "/user/:_id",
  DELETE_USER_DETAILS: "/user/:_id",
  GET_USER_DETAILS_BY_PROOF: "/user/proof/",
  REJOIN_USER: '/rejoinjob',
  RESIGN_USER: '/resignjob',
  SEND_OTP: '/send-otp',
  VERIFY_OTP: '/verifyOtp',

  /***UNREGISTERED USER */
  GET_UNREGISTERED_USERS: "all-unregistered-users",

  /*ROLE URL */
  GET_ROLE_DETAILS: "/roles",
  ADD_ROLE_DETAILS: "/roles",
  UPDATE_ROLE_DETAILS: "/role/:_id",
  DELETE_ROLE_DETAILS: "/role/:_id",

  // TRANSPORT
  GET_LANDTRANSPORT_REPORTS: "report/transportation/:date",
  UPDATE_LAND_ALLOCATION: "/vehicle-allocation/:date",
  ADD_VEHICLE: "/vehicles",
  REMOVE_SCHEDULE_INFO_FROM_VEHICLE: "/vehicle-allocation/remove/reservation/:_id",
  GET_TRANSPORT_DETAILS: '/transportations/:date',
  /*DEPARTMENT URL */
  GET_DEPARTMENT_DETAILS: "/departments",
  ADD_DEPARTMENT_DETAILS: "/departments",
  UPDATE_DEPARTMENT_DETAILS: "/department/:_id",
  DELETE_DEPARTMENT_DETAILS: "/department/:_id",

  /*DRIVER URL*/
  GET_DRIVERDEPARTMENT_SETTING_DETAILS: "/driverAllocation",
  ADD_DRIVERDEPARTMENT_SETTING_DETAILS: "/driverAllocation",
  GET_DRIVERS: "/users/driver-notassigned",
  UPDATE_DRIVERDEPARTMENT_DETAILS: '/driverAllocation',
  GET_ASSIGNED_DRIVERS: "/users/driver-assigned",
  
  /*BED URL */
  GET_BED_DETAILS: "/beds",
  ADD_BED_DETAILS: "/beds",
  UPDATE_BED_DETAILS: "/bed/:_id",
  DELETE_BED_DETAILS: "/bed/:_id",
  CHANGE_STATUS_BED_DETAILS:"/bed/:_id",
  DELETE_BED_TYPES_DETAILS: "/room-type/combination/:_id/:_comboId",

   /*AMENITY URL */
   GET_AMENITY_DETAILS: "/amenities",
   ADD_AMENITY_DETAILS: "/amenities",
   UPDATE_AMENITY_DETAILS: "/amenity/:_id",
   DELETE_AMENITY_DETAILS: "/amenity/:_id",
   UPLAOD_AMENITY_IMAGE:"/upload",

    /*BUILDINGS URL */
    GET_BUILDINGS_DETAILS: "/buildings",
    ADD_BUILDINGS_DETAILS: "/buildings",
    UPDATE_BUILDINGS_DETAILS: "/building/:_id",
    DELETE_BUILDINGS_DETAILS: "/building/:_id",

  CHANGE_STATUS_BED_DETAILS: "/bed/:_id",

    

//  GET_ROOMTYPE_DETAILS:"/room-types",
 

/**Filter Transaction */
GET_ONLINE_PAYMENT: "/online-payment",
GET_ONLINE_PAYMENT_WITH_ID:'online-payment/:_id ',

 /* ROOMTYPE URL */
 FILE_UPLOAD:"/upload",
 GET_ROOMTYPE_DETAILS:"/room-types",
 EARLY_CHECKOUT:"/quick-room/edit/early-checkout",
 CHECK_EXTEND_ROOM:"/quick-room/check/room-extend",
 EXTEND_SAME_ROOM:"/quick-room/edit/room-extend/sameRoom",
 EXTEND_DIFF_ROOM:"/quick-room/edit/room-extend/different",
 GET_ADDONS_CATEGORY_DETAILS:"/addons/by-categories",
 ADD_AADONS_CATEGORY:"/quick-room/add/room-addons",
 ADD_ROOMTYPE_DETAILS:"/room-types",
 UPDATE_ROOMTYPE_DETAILS:"/room-type/:_id",
 DELETE_ROOMTYPE_DETAILS:"/room-type/:_id",
 GET_ROOM_CODE:"/room-code-availability/:code",
 UPDATE_ROOM_TYPE_PRIORITY:"/room-types/priority",
 GET_ROOM_AVAILABLE_BEDCOMBO:"/tour-package/available-bedCombos",
 QUICK_ROOM_UPGRADE: "/quick-room/check/room-upgrade",
 QUICK_ROOM_UPGRADE_EDIT: "/quick-room/edit/room-upgrade",
 QUICKROOM_NOSHOW_ROOM: "/quick-room/edit/noshow",
 GET_MAX_ROOM_LIMIT : "/get/max-room-limit",

 /*ROOMNUMBER URL */
 GET_ROOMNUMBER_COMBINABLE:"/rooms/combinable",
 GET_ROOMNUMBER_DETAILS: "/rooms",
 ADD_ROOMNUMBER_DETAILS: "/rooms",
 UPDATE_ROOMNUMBER_DETAILS: "/room/:_id",
 DELETE_ROOMNUMBER_DETAILS: "/room/:_id",
 CHECK_ROOMNUMBER_UNIQUENESS:"/room-number-availability/:roomNumber",
 DUPLICATE_ROOM:"/rooms/duplicate",
 

  /*COMPANIES URL */
  GET_COMPANIES_DETAILS: "/companies",
  ADD_COMPANIES_DETAILS: "/companies",
  UPDATE_COMPANIES_DETAILS: "/company/:_id",
  DELETE_COMPANIES_DETAILS: "/company/:_id",
  GET_BOAT_QTY: "/vehicles/boat-qty",

  // Accounts Companies
  GET_ACCOUNTS_COMPANIES_DETAILS: "/accounts/companies",

   /*CATEGORY URL */
   GET_CATEGORY_DETAILS: "/categories",
   ADD_CATEGORY_DETAILS: "/categories",
   UPDATE_CATEGORY_DETAILS: "/category/:_id",
   DELETE_CATEGORY_DETAILS: "/category/:_id",

   /*TRIPCOST URL */
   GET_TRIPCOST_DETAILS: "/trip-costs",
   ADD_TRIPCOST_DETAILS: "/trip-costs",
   UPDATE_TRIPCOST_DETAILS: "/trip-cost/:_id",
   DELETE_TRIPCOST_DETAILS: "/trip-cost/:_id",
   GET_LOCATION: "/locations",
   REMOVE_TRIP: "/remove/vehicle-trip",

   /* ADDONS CATEGORY */
   GET_ADDON_CATEGORY_DETAILS: "/addon-categories",
   ADD_ADDON_CATEGORY_DETAILS: "/addon-categories",
   UPDATE_ADDON_CATEGORY_DETAILS: "/addon-category/:_id",
   DELETE_ADDON_CATEGORY_DETAILS: "/addon-category/:_id",
   GET_ADDON_CATEGORY_SUBCATEGORY_DETAILS:"/addon-category/:_id/addon-sub-categories",
   GET_ADDONS_BY_CATEGORIES: "/addons/by-categories",
   SEARCH_BY_ADDON_CATEGORY:"/addon/by-category/:category",
   UPDATE_ADDON_CATEGORY_PRIORITY_DETAILS:"/addon-categories/priority",

    /* AGENT ADDONS CATEGORY */
    GET_AGENT_ADDON_CATEGORY_DETAILS: "/agent-addon-categories",
    ADD_AGENT_ADDON_CATEGORY_DETAILS: "/agent-addon-categories",
    UPDATE_AGENT_ADDON_CATEGORY_DETAILS: "/agent-addon-category/:_id",
    DELETE_AGENT_ADDON_CATEGORY_DETAILS: "/agent-addon-category/:_id",
    //GET_ADDON_CATEGORY_SUBCATEGORY_DETAILS:"/agent-addon-category/:_id/addon-sub-categories",
    //GET_ADDONS_BY_CATEGORIES: "/agent-addons/by-categories",
    //SEARCH_BY_ADDON_CATEGORY:"/agent-addon/by-category/:category",
    UPDATE_AGENT_ADDON_CATEGORY_PRIORITY_DETAILS:"/agent-addon-categories/priority",
    RESET_LOGIN: "reset-login/:_id",

   /* ADDONS SUB CATEGORY */

   GET_ADDON_SUB_CATEGORY_DETAILS: "/addon-sub-categories",
   ADD_ADDON_SUB_CATEGORY_DETAILS: "/addon-sub-categories",
   UPDATE_ADDON_SUB_CATEGORY_DETAILS: "/addon-sub-category/:_id",
   DELETE_ADDON_SUB_CATEGORY_DETAILS: "/addon-sub-category/:_id",
   GET_ADDON_CATEGORY_SUBCATEGORY_DETAILS:"/addon-category/:_id/addon-sub-categories",


    /*VEHICLE URL */
    GET_VEHICLE_DETAILS: "/vehicles",
    ADD_VEHICLE_DETAILS: "/vehicles",
    UPDATE_VEHICLE_DETAILS: "/vehicle/:_id",
    DELETE_VEHICLE_DETAILS: "/vehicle/:_id",
    GET_LAND_VEHICLE_DETAILS:"/get-vehicle",
    GET_BOAT_VEHICLE_DETAILS:"/get-vehicle",
    GET_DRIVERS_DETAILS:"/drivers",
    ADD_PASSANGERS: "/vehicle-allocation/add-staff/:date",

     /*DIVINGCOURSE URL */
     GET_DIVINGCOURSE_DETAILS: "/diving-courses",
     ADD_DIVINGCOURSE_DETAILS: "/diving-courses",
     UPDATE_DIVINGCOURSE_DETAILS: "diving-course/:_id",
     DELETE_DIVINGCOURSE_DETAILS: "diving-course/:_id",
     GET_PACKAGE_OPTIONS: "/getPackageOptions",
     DIVING_STAFF:'/users/diving',


     /*DIVINGDEPARTMENTSETTING URL */
     GET_DIVINGDEPARTMENT_SETTING_DETAILS: "/diving-departments",
     ADD_DIVINGDEPARTMENT_SETTING_DETAILS: "/diving-departments",
     UPDATE_DIVINGDEPARTMENT_SETTING_DETAILS: "diving-department/:_id",
     DELETE_DIVINGDEPARTMENT_SETTING_DETAILS: "diving-department/:_id",
     GET_STAFF_DETAILS:"/users/diving-notassigned",


     
  /*LOCATIONS URL */
   GET_LOCATIONS_DETAILS: "/locations",
   ADD_LOCATIONS_DETAILS: "/locations",
   UPDATE_LOCATIONS_DETAILS: "/location/:_id",
   DELETE_LOCATIONS_DETAILS: "/location/:_id",

   /*Meals URL*/
   GET_MEALS_DETAILS: "./meals",
   ADD_MEALS_DETAILS: "/meals",
   UPDATE_MEALS_DETAILS: "/meal/:_id",
   DELETE_MEALS_DETAILS: "/meal/:_id",
   GET_RESTAURANTS_DETAILS: "/restaurants",

     /*ADDON URL */
     GET_ADDON_DETAILS: "/addons",
     ADD_ADDON_DETAILS: "/addons",
     UPDATE_ADDON_DETAILS: "/addon/:_id",
     DELETE_ADDON_DETAILS: "/addon/:_id",

  /* FACILITIES URL*/
  GET_FACILITY_DETAILS: "/facilities",
  ADD_FACILITY_DETAILS: "/facilities",
  UPDATE_FACILITY_DETAILS: "/facility/:_id",
  DELETE_FACILITY_DETAILS: "/facility/:_id",

   /*CATEGORY URL */
   GET_RESTURANTS_DETAILS: "/restaurants",
   ADD_RESTURANTS_DETAILS: "/restaurants",
   UPDATE_RESTURANTS_DETAILS: "/restaurant/:_id",
   DELETE_RESTURANTS_DETAILS: "/restaurant/:_id",

    /* SURCHARGES URL */
    GET_SURCHARGES_DETAILS: "/sur-charges",
    ADD_SURCHARGES_DETAILS: "/sur-charges",
    UPDATE_SURCHARGES_DETAILS: "/sur-charge/:_id",
    DELETE_SURCHARGES_DETAILS: "/sur-charge/:_id",
   /*TAXES URL*/
   GET_TAXES_DETAILS: "/taxes",
   ADD_TAXES_DETAILS: "/taxes",
   UPDATE_TAXES_DETAILS: "/tax/:_id",
   DELETE_TAXES_DETAILS: "/tax/:_id",

    /*TRAVELAGENT URL */
    GET_TRAVELAGENT_DETAILS: "/agent-categories",
    ADD_TRAVELAGENT_DETAILS: "/agent-categories",
    UPDATE_TRAVELAGENT_DETAILS: "/agent-category/:_id",
    DELETE_TRAVELAGENT_DETAILS: "/agent-category/:_id",
    UPDATE_TRAVEL_AGENT_STATUS: "/travelagent/edit/:_id",
    TRAVEL_AGENT_REGISTRATION:"/agent-dash/get-members",
    TRAVEL_AGENT_CREDITS:"/agent-dash/credit-bookings",
    GET_AGENT_TYPE: "/types",

     /*Package URL*/
   GET_PACKAGE_DETAILS: "/package",
   ADD_PACKAGE_DETAILS: "/package",
   UPDATE_PACKAGE_DETAILS: "/package/:_id",
   DELETE_PACKAGE_DETAILS: "/package/:_id",
   ADD_NEW_PACKAGE_GROUP: "/agent-groups",
   ADD_ACTIVE_TRAVELAGENT: "/travelagent?agentApproved=true",
   GET_ACTIVE_CUSTOMER: "/customers",
   UPDATE_AGENT_GROUP: "/agent-groups/:_id",
   DELETE_AGENT_GROUP: "/agent-groups/:_id",
   GET_PACKAGE_DEPOSITOR: "/tour-package/depositers/:_id",
   GET_NON_PROMOTION: "/package/non-promotions",
   PROMO_OFFLINE_PAYMENT: "/promo-package/offline-payment/:_id",
   PROMO_APPROVE_PAYMENT: "/promo-package/offline-payment/:_id/:paymentId",
   BULK_APPROVE_PAYMENT: "/bulk-payment/offline-payment/:_id/:paymentId",
  BULK_OFFLINE_PAYMENT: "/bulk-payment/offline-payment/:_id",

   GET_CURRENCY_DETAILS: "",
   ADD_CURRENCY_DETAILS: "",
   UPDATE_CURRENCY_DETAILS: "",
   DELETE_CURRENCY_DETAILS: "",
   CHANGE_STATUS_CURRENCY_DETAILS:"",

       /* ADDITIONAL CHARGES URL */
       GET_ADDITIONAL_CHARGES_DETAILS: "/additional-charges",
       ADD_ADDITIONAL_CHARGES_DETAILS: "/additional-charges",
       UPDATE_ADDITIONAL_CHARGES_DETAILS: "/additional-charge/:_id",
       DELETE_ADDITIONAL_CHARGES_DETAILS: "/additional-charge/:_id",

   GET_PACKAGE_DETAILS: "/tour-packages",
   ADD_PACKAGE_DETAILS: "/tour-packages",
   UPDATE_PACKAGE_DETAILS: "/tour-package/:_id",
   DELETE_PACKAGE_DETAILS: "/tour-package/:_id",
   ADD_PACKAGE_BOOKPERIOD_DETAILS: "/tour-package/:_id/periods",
   DUPLICATE_PACKAGE_DETAILS: "/tour-package/duplicate",
   

   GET_CURRENCY_DETAILS: "/currencies",
   ADD_CURRENCY_DETAILS: "/currencies",
   UPDATE_CURRENCY_DETAILS: "/currency/:_id",
   DELETE_CURRENCY_DETAILS: "/currency/:_id",
   GET_CURRENCY_SYMBOLS: "/currency-symbols",

   GET_BOOKING_ENQUIRY_DETAILS: "/booking-enquiries",
   ADD_BOOKING_ENQUIRY_DETAILS: "/booking-enquiries",
   UPDATE_BOOKING_ENQUIRY_DETAILS: "/booking-enquiry/:_id",
   DELETE_BOOKING_ENQUIRY_DETAILS: "/booking-enquiry/:_id",
   UPDATE_BOOKING_ENQUIRY_STATUS: "/booking-enquiry/booking-status/:_id",

   GET_PACKAGE_ENQUIRY_DETAILS: "/package-enquiries",
   UPDATE_PACKAGE_ENQUIRY_STATUS: "/package-enquiry/update-status",

   GET_BOOKING_SETTING_DETAILS: "/lead-sources",
   ADD_BOOKING_SETTING_DETAILS: "/lead-sources",
   UPDATE_BOOKING_SETTING_DETAILS: "/lead-source/:_id",
   DELETE_BOOKING_SETTING_DETAILS: "/lead-source/:_id",
   GET_BOOKING_DATA:"/reservations/",
   GET_TRAVEL_AGENT_BOOKING_DATA: "/agent-reservations",
   GET_BOOKING_SUMMARY: "/reservation/:_id/summary",
   BLOCK_ROOM_TEMP: "/tour-package/temp-room-block/:_id",
   GET_INCLUDED_ADDONS: "/tour-package/locations",
   GET_INCLUDED_ADDONS_LOCATION: "/included-locations",
   ADD_OFFLINE_PAYMENT: "/reservation/:_id/offline-payment",
   UPDATE_RESERVATIONS: "/reservation/:_id",
   USER_UPDATE_RESERVATIONS: "/user-reservation/:_id",
   GET_BOOKING_JSON: "/reservation/:_id/json",
   GET_EXCLUDED_BOAT: "/trip-costs/excludedboat",
   GET_ADDITIONAL_CHARGES: "/additional-charges",
   ADD_TRANSPORTATION_NOTES: "/reservation/:_id/transportation/:transId",
   GET_TRANS_REPORT: "/report/transportation/:date",
   GET_DAILY_REPORT: "/report/today-booking-list/:date",
   GET_ADDONS_REPORT: "/report/today-addons-list/:date",
   GET_FUND_REPORT: "/report/yearly-report/collection/:year/:type",
   GET_MARINE_REPORT:"/report/boat-add-charges/:date",
   EDIT_BOOKING_JSON:"/edit-bookingJson/:_id",
   ROOM_TIMER:"/room-timer",
   UN_BLOCK_ROOM_TEMP: "/tour-package/temp-room-block/:_id",
   EXTEND_ROOM_TIME:"/tour-package/temp-room-block",
   GET_MAINTENCE: "/maintenance-type",
   GET_AVAILABLE_ROOM_TYPES: "/room-change/available-room",
   GET_ROOMS_BY_DATE:"/quick-room/",
  //  Tour packages
  GET_TOUR_PACKAGES: "/packages",
  GET_PACKAGE_BY_ID: "/tour-package/:_id",
  GET_TOUR_PACKAGE_DETAILS: "/tour-packages",
  GET_ROOM_TYPES: "/tour-package/room-types",
  RELEASE_ROOMS: "/room-change/temp-delete-room/:resId",
  UPDATE_INCLUDED_TRANSPORTATIONS:"/reservation/trans-edit/:_id",
  GET_BOOKING_USER_DETAILS: "/reservations/auto-fill/:email",
  GET_PROMOCODE_BY_CODE: "/promo-code-by-code/:code",
  // SURCHARGE CALENDER
  GET_SURCHARGE_CALENDER:"/sur-charges-calender",
  ADD_SURCHARGE_CALENDER:"/sur-charges-calender",

    //PROMOCODE URL
   GET_PROMOCODE_DETAILS: "/promo-codes",
   ADD_PROMOCODE_DETAILS: "/promo-codes",
   UPDATE_PROMOCODE_DETAILS: "/promo-code/:_id",
   DELETE_PROMOCODE_DETAILS: "/promo-code/:_id",
   GET_PROMO_PACKAGE: "/promo-package/details",
   GET_PROMO_PACKAGE_FILTER: "/promo-packages",
   BUY_PROMO_PACKAGE: "/promo-package/buy",
   GET_BULK_PAYMENT: "/bulk-payment",
   BUY_KNOK_PAY: "/bulk-payment/buy",
   BULK_RESERVATION_PAY: "/bulk-payment/reservations-pay",
   UPDATE_SPLIT_AMOUNT: "/bulk-payment/reservations/:_id",
   GET_PROMO_VOCHER_CODE: "/promo-codes/by-code",
   UPDATE_BULK_PAYMENT: "/bulk-payment/edit/:_id",


  GET_TYPEOFPAX_SETTING_DETAILS: "/pax-settings",
  ADD_TYPEOFPAX_SETTING_DETAILS: "/pax-settings",
  UPDATE_TYPEOFPAX_SETTING_DETAILS:"/pax-setting/:_id",

  GET_ROOM_TYPES: "/tour-package/room-types",
  GET_ROOM_NUMBER: "/tour-package/room-by-room-type",
  GET_ADDITIONAL_PACKAGES: "tour-package/room-type-additional-packages",
  ADD_RESERVATIONS: "/reservations",
  GET_BOOKING_DETAILS: "/tour-package/booking-details",
  GET_ADDONS_CATEGORY: "/tour-package/addons/by-categories",
  GET_LOCATION_START_POINTS: "/trip-cost/location/start-points",
  GET_LOCATION_END_POINTS: "/trip-cost/location/:_id/end-points/:type",

  //PERMISSIONS
  GET_PERMISSION_DETAILS: '/permission-settings',
  GET_APP_MODULES_DETAILS: '/app-modules',
  ADD_PERMISSION_DETAILS: '/permission-settings',

  GET_TRAVELAGENT_REGISTRATION_DETAILS:"/travelagent",
  ADD_TRAVELAGENT_REGISTRATION_DETAILS:"/travelagent/add",
  UPDATE_TRAVELAGENT_REGISTRATION_DETAILS:"",
  DELETE_TRAVELAGENT_REGISTRATION_DETAILS:"",
  UPDATE_TRAVELAGENT_REGISTRATION_STATUS:"/travelagent/:_id",
  GET_TRAVELAGENT_REGISTRATION_STATUS_OPTIONS:"/status",

  //RESERVATION 
  REFUND_CUSTOM_RESERVATION_REJECT:"/custom-invoice/refund/:_id",
  GET_RESERVATION_INFO:"/reservation/:_id",
  REFUND_RESERVATION_REJECT:"/agent-reservation/refund/:_id",
  GET_AGENT_RESERVATION_INFO:"/agent-reservation/:_id",
  CONFIRM_AGENT_PAYMENT: "/agent-reservation/payment/:_id/:payId",
  AGENT_BOOKING_APPROVAL:"/agent-reservation/approve/:_id",
  REJECT_AGENT_BOOKING: "/agent-reservation/reject/:_id",
  CHECK_CUSTOMER_REGISTER: "/reservation/wallet-check/:_id",

  UPDATE_GEUST_RESERVATION_INFO:"/reservation-room/:_id",
  SET_RESERVATION_PAYMENT_STATUS:"/reservation/:_id/payment/:paymentid",
  TRANSPORT_UPDATE_PAX: "/transportations/pax-wise/:_id/:transId",
  SEND_PAYMENT_REJECT_MAIL: "/send/payment-reject/mail",
  DAILY_BOOK_DATE_REPORT: "/daily-bookings/report",
  SNORKELING: "/snorkeling",

  //AGENT PROFILE
  GET_AGENT_PROFILE_DETAILS: "/travelagent-info/:_id",
  ADD_AGENT_PROFILE_DETAILS: "/travelagent-info/:_id",
  UPDATE_AGENT_PROFILE_DETAILS: "/travelagent-info/:_id",
  AGENT_FILE_UPLOAD:"s3/upload",
  GET_AGENT_DETAILS: "/travelagent/:_id",
  GET_ROOMS_BY_ROOMTYPES: "/rooms-by-room-type",
  UPDATE_AGENT_PERSONAL_DETAILS: "/travelagent/edit/:_id",


  //Dashboard API
  GET_REMAINDERS_DASH: "/dash/reminder",
  GET_PAYMENT_REMINDER: "/dash/payment-remainder/:date",
  GET_EXPIRED_INVOICE: "/dash/payment-remainder/:date?type=expired",
  GET_DASBOARD_GRAPH:"/dash/graph/",
  GET_DASHBOARD_PAYTORESORT:"/dash/paytoresort/:date",
  GET_DASHBOARD_TODAYPICKUP:"/dash/todayPickUp/:date",
  GET_DASHBOARD_CARDCOUNT:"/dash/cards-count/:date",
  GET_DASHBOARD_CURRENTLIST:"/dash/currentList/:date",
  GET_DASHBOARD_UNCONFIRMED_PAY:"/dash/unconfirmed-payments",
  GET_DASHBOARD_CARDS_INFO: "/dash/cards-count/info/:date",
  GET_DASHBOARD_MONTHLY_REPORT : "/dash/monthly-report",
  GET_DAILY_REPORT_RESRV :"/daily-report/reservations",
  GET_DASHBOARD_MONTHLY_SALES_REPORT: "/dash/monthly-report/sale",
  GET_DAILY_SALES_REPORT: "/daily-report/sales",
  GET_YEARLY_REPORT_INFO: "/report/yearly-report/:date",
  GET_COUNTRY_REPORT_INFO:"/report/yearly-report/country",
  GET_AGENT_REPORT_INFO: "/report/yearly/agent-sales/:year",
  GET_ADDON_YEARLY_REPORT: "report/yearly-report/addons/:date",
  GET_TOP_NIGHT_INFO: "/report/yearly-report/nights/:year",
  GET_BOOKING_FROM_INFO: "/report/yearly-report/findus/:year",
  GET_TOP_PACKAGE_INFO:"/report/yearly-report/package/:year",
  GET_PAX_REPORT_TRANSPORTATION:"/yearly-report/customer-wise/transportation/:year",

  GET_AGENT_EXIPIRED_PAYMENT:"dash/payment-remainder/:date?type=expired&agent=true",
  GET_AGENT_PARTIAL_PAYMENT: "dash/payment-remainder/:date?agent=true",
  GET_AGENT_UNCONFIRMED: "/dash/unconfirmed-payments?agent=true",
  // agent payout

  GET_AGENTS_PAYOUT: "/yearly-report/comission-agent/yearly-split/:year",
  GET_AGENT_PAYOUT_COMMISSION: "/yearly-report/comission-agent/yearly-split/agent/:year/:agentId",
  PAYOUT_COMMISIONS: '/freelance-payouts',
  WALLET_PAYOUTS:"/wallet-payout",
  GET_UNAPPROVED_BOOKINGS: '/agent-reservations',
  GET_AGENT_COMMISION_DETAILS: '/agent-comissions/:year',
  GET_MONTHLY_REPORT_STATE_SPLITUP : "/report/monthly-report/state-split/:month/:year",
  GET_PORTAL_BOOKING_SALES: "/monthly-report/booking/portal-split/:month/:year",
  
  // agent Files
GET_AGENT_FILES: '/agent-email-file',
UPLOAD_AGENT_FILES:'/agent-email-file',
DELETE_AGENT_FILES: '/agent-email-file/:_id',
UPDATE_AGENT_FILES: '/agent-email-file/:_id',

// agent images
  GET_AGENT_IMAGES: '/banner',
  UPLOAD_AGENT_IMAGES: "/banner",
  UPDATE_AGENT_IMAGES: '/banner/?=:_id',
  DELETE_AGENT_IMAGES:  '/banner/?=:_id',

  //Leave API
  GET_LEAVE_LIST:"/users/leave",
  UPDATE_LEAVE_LIST:"/user/leave/:_id",
  LEAVE_APPROVE: "/leave/approve",

  //Online status
  GET_PAYMENT_STATUS: "/online-payment/:_id",
  CHANGE_ROOM: "/rooms-change/:resId",
  BLOCK_ROOM:"/rooms-block/:_id",
  UN_BLOCK_ROOM: "/rooms-unblock/:roomId/:blockId",
  BLOCK_MULTIPLE_ROOM: "/rooms-multi-block",
  BLOCK_MULTIPLE_ROOM_List:"/block-rooms",
  UN_BLOCK_MULTIPLE_ROOMS: "/unblock-rooms/:blockId",

  //Maintenance
  GET_MAITENANCE_INFO: "/maintenance-type",
  UPDATE_MAITENANCE_INFO: "/maintenance-type/:_id",

  //Dashboard Monthly Report 
  GET_OCCUPENCY_INFO: "/report/room-type",

  //Agent Related URLs
  GET_AGENT_ADDONS : "/agent-addons",
  UPDATE_AGENT_ADDONS: "/agent-addons/approve/:_id",
  GET_AGENTS: "/travelagent?agentApproved=true&fetchAll=true",
  UPDATE_DISCOUNT_AMOUNT:'/quick-room/edit/additional-discount',
  REMOVE_ROOM: '/room-delete/:roomId/:reservationId',
  DELETE_PAX: '/room-pax-delete/:roomId/:reservationId',
  //UserList related URLs
  GET_ADMIN_USER_LIST: "/all-customers",
  UPDATE_ADMIN_USER_STATUS:"/customers/:_id",
  GET_LOGS: "/reservations/logs/:_id",
  ADD_PAX: "/reservations/add-pax/:_id/:roomId",
  TRANS_HISTORY: "/payments/reservation/:id",
  ROOMS_AVAILABILITY: "/rooms-availability",
  LAST_MINUTE_BOOKING_DAY: "/last-minute-booking-day",
  DASH_LAST_MINUTE_BOOKINGS: "/dash/last-minute-bookings/:date",

  //Report related URLS
  GET_DAILY_REPORT_INFO: "/report/daily-report/collection/:fromdate/:todate",
  GET_DAILY_REFUND_REPORT_INFO:"/report/daily-report/refund/:date",
  GET_PACKAGE_REPORT: "/report/monthly-report/package-split/:month/:year",
  GET_YEARLY_SALES_REPORT: "/yearly-report/sales/monthly-split/:year",
  GET_BALANCE_REPORT:"/yearly-report/pending-payment/:year",
  GET_REFUND_REPORT:"/yearly-report/active-booking-refund/yearly-split/:year",
  GET_CONFIRMATION_REPORT:"/yearly-report/cancelled-booking/yearly-split/:year",
  GET_MONTHLY_SALES_REPORT: "/yearly-report/collection/monthly-split/:year/:month",
  GET_DEPOSIT_REPORT:"/yearly-report/deposit/split/:year",
  GET_MONTHLY_BALANCE_REPORT: "/monthly-report/pending-payment/:year/:month",
  GET_MONTHLY_TRANS_REPORT: "/yearly-report/addons-trans/monthly-split/:year/:month",
  GET_REWARDS_REFERRAL: "/wallet/customer-rewards?month=:monthData&year=:yearData",
  GET_REWARDS_REPORT: "/referal-rewards",
  GET_REWARDS_DETAILS: "/referal-rewards/:_id",
  GET_TOP_LEVEL_AGENT: "/top-agents",

  // Events related URLS
  GET_EVENTS_LIST: "/events",
  GET_EVENT_DETAILS: "/event/:_id",
  GET_EVENT_CHECKLIST: "/event/checklists/:eventId",
  ADD_EVENT_CHECKLIST: "/event/checklist",
  UPDATE_EVENT_CHECKLIST: "/event/checklist/:_id",
  GET_EVENT_PlANLIST: "/event/planlists/:eventId",
  ADD_EVENT_PLANLIST:"/event/planlist",
  DELETE_EVENT_PLANLIST: "/event/planlist/:_id",
  GET_EVENT_ROOMNUMBER: "/event/available-rooms/:_id/:eventDate",
  ADD_EVENT_MEMBERS: "/event/memberlist",
  GET_EVENT_MEMBERS: "/event/memberlists/:eventId",
  DELETE_EVENT_MEMBERS: "/event/memberlist/:_id",
  UPDATE_MEMBER_CHECKEDIN: "/event/memberlist/update/checked-in/:memberListId",
  PRINT_DETAILS:"/event/printout/:_id",
  SAVE_OFFLINE_EVENT_PAYMENT: "/event/offline/payment",
  GET_EVENTS_MONTHLY_REPORT: "/monthly-report/events/:year/:month",
  SEND_EVENT_INVOICE_MAIL: "/event/send-invoice/:memberListId",

  // customer rewards
  GET_REWARDS: "/reward-settings",
  GET_REWARD: "/reward-setting/:_id",

  // social share
  SOCIAL_SHARE_URL: "/social-share",

  // staff performance

  GET_STAFF_PERFORMANCE_DETAILS: "/staff-performance",
  GET_STAFF_PERFORMANCE_REPORT_DETAILs: "/staff-performance/staff/:staff/:year",

  //booking edit price
  UPDATE_PRICE: '/reservations/edit-price/:_id',
  //e-wallet setting
  GET_EWALLET_SETTING_DETAILS: '/wallet-setting',
  POST_EWALLET_SETTING_DETAILS: '/wallet-setting',
  PUT_EWALLET_SETTING_DETAILS: '/wallet-setting/:_id',

  // amenities category
  GET_OR_ADD_CATEGORY_DETAILS: '/amenities/categories',
  GET_BY_CATEGORY: '/amenities/category/:_id',
  UPDATE_OR_DELETE_CATEGORY_DETAILS: '/amenities/category/:_id',

  //Feedback categories
  GET_OR_ADD_FEEDBACK_CATEGORY: '/feedback-categories',
  UPDATE_OR_DELETE_FEEDBACK_CATEGORY: '/feedback-category/:id',
  GET_OR_ADD_FEEDBACK_QUESTION: '/feedback-questions',
  UPDATE_OR_DELETE_FEEDBACK_QUESTION: '/feedback-question/:id',
  GET_CUSTOMER_FEEDBACK: '/feedback',
  // surcharge description
  SURCHARGE_DESCRIPTION: '/surcharge-description',

  //boatSchedule description
  BOAT_SCHEDULE_DESCRIPTION: '/boatSchedule-description',

  // Custom Invoice Items
  Get_Custom_Invoice_Items: "/custom-invoice-items",
  Add_Custom_Invoice_Item: "/custom-invoice-items",
  Get_Single_Custom_Invoice_Item: "/custom-invoice-item/:_id",
  Update_Custom_Invoice_Item: "/custom-invoice-item/:_id",
    //custom invoice
  CUSTOM_INVOICE: '/custom-invoices',
  CUSTOM_INVOICE_ID: '/custom-invoice/:_id',
  GET_INVOICE_ITEMS: '/custom-invoice-items',
  BLOCK_ROOMS: '/chart-resort/block-rooms',
  PUT_BLOCK_ROOMS: '/custom-invoice/blocked/rooms',
  GET_BLOCKED_ROOMS: '/custom-invoice/blocked/rooms',
  OFFLINE_PAYMENT: '/custom-invoice/payment-offline/:_id',
  ASSIGN_PROPERTY: '/custom-invoice/assign/property',
  DATE_CHANGE: '/custom-invoice/chart-resort/date-edit/:_id',
  REMOVE_AND_BLOCK: '/custom-invoice/chart-resort/remove-and-block/:_id',

  //resort operating time
  RESORT_OPERATING_TIME: "/operation-period",
  RESORT_OPERATING_TIME_WITH_ID: "/operation-period/:_id",
  GET_COUNTRYWISE_PAX_LIST: "/report/country-split",

  // Inlfuencers package routes
  ADD_INFLUENCERS_PACKAGE: "/influencer-package",
  UPDATE_AND_DELETE_INFLUENCERS_PACKAGE: "/influencer-package/:_id",

  // permission Settings

  GET_PERMISSION_SETTINGS : "/permission-settings",
  GET_PERMISSION_SETTINGS_BYID: "/permission-setting/:_id",
  ADD_PERMISSION_SETTINGS : "/permission-settings",
  UPDATE_PERMISSION_SETTINGS: "/permission-setting/:_id",
  DELETE_PERMISSION_SETTINGS: "/permission-setting/:_id",
  GET_PERMISSION_BY_ROLE_DEPARTMENT: "/permission-settings",

   // Resort Details
   ADD_RESORT_DETAIL: "/resortdetail",
   GET_RESORT_DETAIL: "/resortdetail",
   UPDATE_RESORT_DETAIL: "/resortdetail/update/:_id",
 
   //Mail
   ADD_MAIL_CONTENT: "/mail-content",
   GET_MAIL_CONTENT: "/mail-content",
   DELETE_MAIL_CONTENT: "/mail-content",
   UPDATE_MAIL_CONTENT: "/mail-content/:contentId",
   UPDATE_MAIL_CONTENT_STATUS: "/mail-content/update-status/:contentId",

  //  Package Report URLS
  GET_PACKAGE_PERFORMANCE_REPORT:"/package-performance",
  GET_PACKAGE_PERFORMANCE_REPORT_BY_PAX:"/package-performance/by-pax",
  GET_PACKAGE_PERFORMANCE_PORTAL_REPORT:"/package-performance/by-portal",
  GET_BALANCE_REPORT_URL : "/reservation/checkOutBalance",
  GET_ADDONS_REPORT: "/report/addons",
  GET_FOC_REPORT: "/report/foc",

  // Room Capacity URLS
  GET_ROOM_CAPACITY_URL: "/room-capicity/report",

  // Get Server Date Time
  GET_SERVER_DATE_TIME: "/server/dateTime"
  
}